/* Author: Agile Pixel */
jQuery(document).ready(function ($) { 

	var $carousel = $('.page__carousel--js'),
      $search_start = $('.search--action'),
      $gmap = $('.google-maps'),
      $carousel_title = $('.carousel__title'),
      $homepage_content = $('.sector__content'),
      $sector_select = $('.sector__tabs__item'),
      $product__list = $('.product__list'),
      $address__search = $('#address_search'),
      $product_search = $('#product_search'),
      $store__location = $('.results_wrapper');

	if ($.exists($carousel)) {
    $carousel.owlCarousel({
      items:1,
      lazyLoad:true,
      loop:true,
      autoplay:true,
      nav: true,
      autoplayTimeout:7000,
      autoplayHoverPause:false
    });
	}
  if($.exists($search_start)){
    $search_start.on('click', function(e){
      if( !$('.search__input').hasClass('fadeInLeft')){
        e.preventDefault();
        $('.search__input').addClass('fadeInLeft');
      }
    });
  }
  if ($.exists($product_search)) {
    $product_search.fastLiveFilter('.downloads--js');
  }
  if($.exists($carousel_title)){
    $(window).resize(function (){
      $carousel_title.css({
          'margin-top' : -$carousel_title.height()/2,
          'opacity' : 1
      });
    });
    $(window).resize();
  }
  if($.exists($homepage_content)){
    $homepage_content.owlCarousel({
      items:1,
      loop:false,
      margin:0,
      dots: false,
      nav: true,
      URLhashListener:true,
      autoplayHoverPause:true,
      startPosition: 'URLHash'
    });
  }
  if($.exists($sector_select)){
    $sector_select.on('click', function(e){
      $sector_select.removeClass('active');
      $(this).addClass('active');
    });
  }

  $('.navigation__list').slicknav({
    prependTo: '.widget__header',
    label: ''
  });

  $store__location.on('click', function(){
    $store__location.removeClass('active');
    $( this ).addClass('active');
  });

  if($.exists($address__search)){
      $address__search.prepend('<h3 class="gmaps__title">Burners &amp; Spares Locator <i class="arrow arrow--orange"></i></h3>');

      $('.slp_ui_button').val('Search');
  }



// To keep our code clean and modular, all custom functionality will be contained inside a single object literal called "buttonFilter".

var buttonFilter = {

  // Declare any variables we will need as properties of the object

  $filters: null,
  $reset: null,
  groups: [],
  outputArray: [],
  outputString: '',

  // The "init" method will run on document ready and cache any jQuery objects we will need.

  init: function(){
    var self = this; // As a best practice, in each method we will asign "this" to the variable "self" so that it remains scope-agnostic. We will use it to refer to the parent "buttonFilter" object so that we can share methods and properties between all parts of the object.

    self.$filters = $('#Filters');
    self.$reset = $('#Reset');
    self.$container = $('#Container');

    self.$filters.find('.product-finder__item').each(function(){
      self.groups.push({
        $buttons: $(this).find('.filter'),
        active: ''
      });
    });

    self.bindHandlers();
  },

  // The "bindHandlers" method will listen for whenever a button is clicked.

  bindHandlers: function(){
    var self = this;

    // Handle filter clicks

    self.$filters.on('click', '.filter', function(e){
      e.preventDefault();

      var $button = $(this);

      // If the button is active, remove the active class, else make active and deactivate others.

        $button.hasClass('active') ?
        $button.removeClass('active') :
        $button.addClass('active').siblings('.filter').removeClass('active');


      self.parseFilters();
    });

    // Handle reset click

    self.$reset.on('click', function(e){
      e.preventDefault();

      self.$filters.find('.filter').removeClass('active');

      self.parseFilters();
    });
  },

  // The parseFilters method checks which filters are active in each group:

  parseFilters: function(){
    var self = this;

    // loop through each filter group and grap the active filter from each one.

    for(var i = 0, group; group = self.groups[i]; i++){
      group.active = group.$buttons.filter('.active').attr('data-filter') || '';
    }

    self.concatenate();
  },

  // The "concatenate" method will crawl through each group, concatenating filters as desired:

  concatenate: function(){
    var self = this;

    self.outputString = ''; // Reset output string

    for(var i = 0, group; group = self.groups[i]; i++){
      self.outputString += group.active;
    }

    // If the output string is empty, show all rather than none:

    !self.outputString.length && (self.outputString = 'all');

    console.log(self.outputString);

    // ^ we can check the console here to take a look at the filter string that is produced

    // Send the output string to MixItUp via the 'filter' method:

    if(self.$container.mixItUp('isLoaded')){
      self.$container.mixItUp('filter', self.outputString);
    }
  }
};

// On document ready, initialise our code.

$(function(){

  // Initialize buttonFilter code

  buttonFilter.init();

  // Instantiate MixItUp

  $('#Container').mixItUp({
    controls: {
      enable: false // we won't be needing these
    },
    callbacks: {
      onMixFail: function(){
        //alert('No items were found matching the selected filters.');
      }
    }
  });
});


$('.store-locator').on('click', '.results_wrapper', function(){

    $('.results_wrapper').removeClass('active');
    $( this ).addClass('active');

});




$('#codeLookUp').submit(ajaxSubmit);
function ajaxSubmit(){
var newContactForm = $(this).serialize();
var flag = 0;
    $('.require', '#codeLookUp').each(function(){
        var inputVal = $(this).val();
if($.trim(inputVal) === ""){
            flag = 1;
            $(this).next().html("Can't be blank");
            $(this).next().show();
        }
        else{
            $(this).next().hide();
        }
    });
    if(flag){
        return false;
    }
    $('.lookup__result, .alert__error, .lookup__description').remove();

    $.ajax({
        type:"POST",
        url: "/wp-admin/admin-ajax.php?action=codeLookUp",
        data: newContactForm,
        success:function(data){
          if ( typeof data != 'object' ){
                    data = $.parseJSON( data );
                }
            if(typeof data.count !== 'undefined' && data.count > '0'){
              $(".lookup").append($('<div class="lookup__result"><p>'+data.new_code+'</p></div><p class="lookup__description">Description: '+data.description+'</p>'));
            }else{
              $(".lookup").append($('<p class="alert alert__error">No product found</p>'));
            }
        },
        error: function(errorThrown){
            $(".lookup").append($('<p class="alert alert__error">No product found</p>'));
        }
    });
    return false;
}



});

